import { get } from "@churchofjesuschrist/universal-env";

const { STUDY_NOTEBOOK_URL } = get();

const emptyToUndefined = (obj) =>
    obj && Object.keys(obj).length ? obj : undefined;

// from api
const annotationFromApi = ({ annotationId, ...annotation }) => ({
    ...annotation,
    id: annotationId,
    folders: annotation.folders.map(({ folderId }) => folderId),
    lang: annotation.locale,
    locale: undefined,
    note: annotation.note || {},
    setId: annotation.setId || null,
    tags: annotation.tags.map(({ tagId }) => tagId),
});

export const bookmarkFromApi = ({ annotationId, ...bookmark }) => ({
    ...bookmark,
    id: annotationId,
    highlights: undefined,
    lang: bookmark.locale,
    locale: undefined,
    bookmark: {
        ...bookmark.bookmark,
        annotationId,
    },
});

export const foldersFromApi = (folders) =>
    folders.map((folder) => folderFromApi(folder)).filter(({ id }) => id);

// Let's keep the folder data whole for now.
export const folderFromApi = ({ folderId, ...folder }) => ({
    id: folderId,
    ...folder,
});

export const setFromApi = ({ setId, ...set }) => ({
    id: setId,
    ...set,
});

export const tagFromApi = ({
    annotationsCount,
    created,
    tagId,
    name,
    timestamp,
}) => ({
    annotationsCount,
    created,
    id: tagId,
    name,
    timestamp,
    href: `${STUDY_NOTEBOOK_URL}/tags/${tagId}`,
});

export const highlightFromApi = ({ color, style, ...offset }) => ({
    ...offset,
    clear: color === "clear",
    color: color === "clear" ? "yellow" : color,
    underline: style === "red_underline" || style === "red-underline",
});

export const refFromApi = ({ lang, locale, ...ref }) => ({
    ...ref,
    key: ref.pid,
    id: ref.pid,
    href: "",
    lang: locale || lang,
});

export const highlightAnnotationFromApi = (annotation) => ({
    ...annotationFromApi(annotation),
    highlights: annotation?.highlights?.map(highlightFromApi) || [],
    refs: (annotation.refs || []).map(refFromApi),
});

// to api
const cleanBookmark = ({ name, offset, pid, sort, uri }) => ({
    name,
    offset,
    pid,
    sort,
    uri,
});

export const cleanHighlight = ({
    startOffset,
    endOffset,
    pid,
    uri,
    color,
    clear,
    underline,
}) => ({
    startOffset,
    endOffset,
    pid,
    uri,
    color: clear ? "clear" : color,
    // TODO: Craig is making changes to how highlights are styled and how they are saved.
    // We will need to update the following line when his updates are done.
    style: underline ? "red-underline" : undefined,
});

const cleanFolders = (folder) => folder.id;
const cleanTags = (tag) => tag.name;

const cleanRef = ({ name, uri, docId, pid, contentVersion, locale }) => ({
    name,
    uri,
    docId,
    pid,
    contentVersion,
    locale,
});

// highlights in this function is an array of all the highlights from the reducer that are a part of the annotation. The bookmark is from the bookmarks annotation.
export const annotationToApi = (annotation) => ({
    bookmark:
        emptyToUndefined(annotation.bookmark) &&
        cleanBookmark(annotation.bookmark),
    contentVersion: annotation.contentVersion,
    device: annotation.device,
    docId: annotation.docId,
    folders:
        emptyToUndefined(annotation.folders) &&
        annotation.folders.map(cleanFolders),
    highlights: emptyToUndefined(annotation.highlights)
        ? annotation.highlights.map(cleanHighlight)
        : undefined,
    annotationId: annotation.id,
    note: emptyToUndefined(annotation.note),
    refs: emptyToUndefined(annotation.refs?.map(cleanRef)),
    source: annotation.source,
    setId: annotation.setId || undefined,
    tags: emptyToUndefined(annotation.tags.map(cleanTags)),
    type: annotation.type,
    uri: annotation.uri,
    locale: annotation.lang,
});

export const folderToApi = ({ id: folderId, ...folder }) => ({
    folderId,
    ...folder,
});

export const tagToApi = ({
    id: tagId,
    // eslint-disable-next-line no-unused-vars -- define so it isn't included in ...tag
    href,
    ...tag
}) => ({ tagId, ...tag });

export const setToApi = ({ id: setId, ...set }) => ({ setId, ...set });
